<template>
  <v-card>
    <v-tabs
      v-model="tab"
      show-arrows
      fixed-tabs
    >
      <v-tab
        v-for="(tabItem,index) in tabs"
        :key="tabItem.title"
        @click="reRenderComponent(index)"
      >
        <span>{{ tabItem.title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <!-- <v-tab-item>
        <evoting-candidate></evoting-candidate>
      </v-tab-item> -->
      <v-tab-item>
        <evoting-election :key="componentKey"></evoting-election>
      </v-tab-item>
      <v-tab-item>
        <evoting-result
          :key="componentKey2"
        ></evoting-result>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
// import EvotingCandidate from './EvotingCandidate.vue'
import EvotingElection from './EvotingElection.vue'
import EvotingResult from './EvotingResult.vue'
import { ref, watch, onMounted } from '@vue/composition-api'

export default {
  name: 'Evoting',
  components: {
    // EvotingCandidate,
    EvotingElection,
    EvotingResult,
  },
  data() {
    return {
      page: 1,
      componentKey: 0,
      componentKey2: 0,
      totalPages: 0,
      tab: '',
      tabs: [{ title: 'Pemilihan' }, { title: 'Hasil Vote' }],
    }
  },
  methods: {
    reRenderComponent(index) {
      switch (index) {
        case 0:
          this.componentKey += 1
          break
        case 1:
          this.componentKey2 += 1
          break
        default:
          break
      }
    },
  },
}
</script>

<style>
</style>
