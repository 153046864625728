<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :items="elections"
          :total-pages.sync="totalPages"
          :headers="headers"
          :search="search"
          title="Pemilihan"
          subtitle="Halaman untuk menambahkan data pemilihan"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          @delete-item="confirmDestroy"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || arrayName[0].name === '' || arrayName[1].name === ''"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Pemilihan': 'Edit Pemilihan'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="election.name"
                label="Nama Pemilihan"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="election.class_uuid"
                label="Kelas"
                :items="classes"
                item-text="name"
                item-value="uuid"
                dense
                clearable
                outlined
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <v-menu
              v-model="startAtMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="election.start_date"
                  label="Tanggal Mulai"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  clearable
                  v-on="on"
                  @click="autofillStartDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="election.start_date"
                :first-day-of-week="0"
                locale="id-id"
                color="primary"
                @input="startAtMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <v-menu
              v-model="endAtMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="election.end_date"
                  label="Tanggal Berakhir"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  clearable
                  v-on="on"
                  @click="autofillEndDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="election.end_date"
                :first-day-of-week="0"
                locale="id-id"
                color="primary"
                @input="endAtMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col

            cols=""
            class="pt-0 pb-5"
          >
            <h3 class="mb-4">
              DATA KANDIDAT
            </h3>
            <v-card
              v-for="(item, index) in arrayName"
              :key="index"
              outlined
              class="mb-4"
            >
              <v-card-text>
                <div class="d-flex justify-space-between">
                  <h3 class="mb-4">
                    KANDIDAT {{ index + 1 }}
                  </h3>
                  <v-btn
                    v-if="item.index > 2"
                    color="primary pa-2"
                    icon
                    small
                    @click="deleteInput(index)"
                  >
                    <v-icon>
                      {{ icons.mdiCloseCircleOutline }}
                    </v-icon>
                  </v-btn>
                </div>
                <div class="mb-4">
                  <FileInput
                    v-if="dialog == 'add'"
                    :value="item.image"
                    :prependicon="icons.mdiImageMultipleOutline"
                    label="Foto Kandidat"
                    outlined
                    dense
                    @input="getFile($event, index)"
                  ></FileInput>
                  <FileInput
                    v-if="dialog == 'edit'"
                    :value="image[index]"
                    :prependicon="icons.mdiImageMultipleOutline"
                    label="Foto Kandidat"
                    outlined
                    dense
                    @input="getFile($event, index)"
                  ></FileInput>
                </div>
                <v-text-field
                  v-model="item.name"
                  :label="`Nama Kandidat ${item.index}`"
                  dense
                  outlined
                ></v-text-field>
                <!-- <v-text-field
                  v-if="dialog == 'edit'"
                  v-model="item.value"
                  :label="`Nama Kandidat ${item.index}`"
                  :append-icon="arrayName.length <= 2 ? '' : icons.mdiTrashCanOutline"
                  dense
                  outlined
                  @click:append="deleteCandidate(item.uuid, election.uuid)"
                ></v-text-field> -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            class="py-0"
          >
            <div class="pb-5">
              <a
                href=""
                style="text-decoration: none"
                @click.prevent="addInput"
              ><b>TAMBAH KANDIDAT BARU </b></a>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Pemilihan"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import FileInput from '@/views/components/FileInput.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiCloseCircleOutline, mdiImageMultipleOutline, mdiTrashCanOutline } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

export default {
  name: 'EvotingElection',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    MainCard,
    SkeletonLoaderTable,
    FileInput,
  },
  setup(props, context) {
    const icons = ref({
      mdiTrashCanOutline,
      mdiCloseCircleOutline,
      mdiImageMultipleOutline,
    })
    const attrs = ref({
      class: 'mb-6',
      boilerplate: false,
      elevation: 5,
      types: {},
    })
    const page = ref(1)
    const search = ref('')
    const dialog = ref('')
    const width = ref(600)
    const totalPages = ref(0)
    const totalItems = ref(0)
    const modalDialog = ref(false)
    const confirmDialog = ref(false)
    const isLoadingTable = ref(true)
    const isLoadingData = ref(false)
    const isLoadingButton = ref(false)
    const startAtMenu = ref(false)
    const endAtMenu = ref(false)
    const classUuid = ref('')
    const candidateUuid = ref('')
    const filterQuery = ref({ search: '' })
    const headers = ref([
      { text: 'No', value: 'index' },
      { text: 'Jenis Pemilihan', value: 'name' },
      { text: 'Kandidat', value: 'candidateName' },
      { text: 'Kelas', value: 'class.name' },
      { text: 'Tanggal Mulai', value: 'start_date' },
      { text: 'Tanggal Berhenti', value: 'end_date' },
      { text: 'Actions', value: 'actions' },
    ])

    const arrayName = ref({
      name: [
        {
          index: 1,
          uuid: '',
          name: '',
          image: null,
        },
        {
          index: 2,
          uuid: '',
          name: '',
          image: null,
        },
      ],
    })

    const candidate = ref({
      evoting_uuid: '',
      name: [],
    })
    const formValid = ref(false)
    const classes = ref([])
    const userUuid = ref('')
    const image = ref([])
    const imageInput = ref([])
    const elections = ref([])
    const election = ref({
      uuid: '',
      users_uuid: JSON.parse(localStorage.getItem('user')).user.uuid,
      class_uuid: '',
      name: '',
      candidate: [],
      candidate_uuid: [],
      start_date: '',
      end_date: '',
    })

    const school = ref('')
    const schoolUuid = ref('')

    const getSchoolUuid = () => {
      school.value = JSON.parse(localStorage.getItem('user')).user.details
      school.value.forEach(el => {
        if (el.school === undefined) {
          schoolUuid.value = el.uuid

          return
        }

        if (el.school === null) {
          return
        }
        userUuid.value = JSON.parse(localStorage.getItem('user')).user.uuid
        schoolUuid.value = el.school.uuid
      })

      return true
    }

    const checkClassUuid = () => {
      if (!JSON.parse(localStorage.getItem('user')).user.details[0].teacher?.class_advisor) {
        classUuid.value = ''
      } else {
        classUuid.value = JSON.parse(localStorage.getItem('user')).user.details[0].teacher.class_advisor[0].uuid
      }
    }

    const showSnackbar = snackbarData => {
      context.root.$store.dispatch('snackbar/showSnackbar', snackbarData)
    }
    const getFile = (data, index) => {
      for (let i = 0; i <= index; i++) {
        imageInput.value.push('null')
      }
      imageInput.value[index] = data
    }
    const addInput = () => {
      arrayName.value.push({
        index: arrayName.value.length + 1,
        uuid: '',
        name: '',
        image: null,
      })
    }

    const deleteInput = () => {
      if (arrayName.value.length <= 2) {
        showSnackbar({
          text: 'TELAH MENCAPAI MAKSIMAL HAPUS',
          color: 'danger',
        })
      } else {
        arrayName.value.pop(arrayName.value.length - 1)
      }
    }

    const listElection = async (params = {}) => {
      isLoadingData.value = true
      await context.root.$services.ApiServices.list('evoting', {
        ...params,
        class: classUuid.value,
        page: page.value,
      }).then(
        ({ data }) => {
          elections.value = data.data.map((elections, index) => ({
            ...elections,
            index: index + 1,
          }))
          totalItems.value = data.meta.total
          totalPages.value = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      isLoadingTable.value = false
      isLoadingData.value = false
    }

    const resetForm = () => {
      election.value.uuid = ''
      election.value.name = ''
      election.value.candidate = []
      election.value.class_uuid = []
      election.value.start_date = ''
      election.value.end_date = ''
      arrayName.value = [
        {
          index: 1,
          uuid: '',
          name: '',
          image: null,
        },
        {
          index: 2,
          uuid: '',
          name: '',
          image: null,
        },
      ]
      candidate.value = []
      image.value = []
      imageInput.value = []
    }

    const showFormAdd = () => {
      dialog.value = 'add'
      resetForm()
      if (classUuid.value === '') {
        showSnackbar({
          text: 'Anda Bukan Wali Kelas',
          color: 'error',
        })
      } else {
        resetForm()
        modalDialog.value = true
      }
    }

    const add = async () => {
      isLoadingButton.value = true
      const formData = new FormData()
      formData.append('users_uuid', election.value.users_uuid)
      formData.append('name', election.value.name)
      formData.append('class_uuid', election.value.class_uuid)
      formData.append('start_date', election.value.start_date)
      formData.append('end_date', election.value.end_date)
      arrayName.value.forEach((el, index) => {
        formData.append('candidate[]', el.name)
        formData.append('image[]', imageInput.value[index])
      })

      await context.root.$services.ApiServices.add('evoting', formData).then(
        ({ data }) => {
          showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          listElection()
          resetForm()
          modalDialog.value = false
        },
        err => {
          resetForm()
          isLoadingButton.value = false
          console.error(err)
        },
      )
      isLoadingButton.value = false
    }

    const getClass = async (params = {}) => {
      isLoadingData.value = true
      await context.root.$services.ApiServices.list('class', {
        ...params,
        page: page.value,
        school_uuid: schoolUuid.value,
      }).then(
        async ({ data }) => {
          classes.value = data.data
          totalItems.value = data.meta.total
          totalPages.value = data.meta.last_page
        },
        err => console.error(err),
      )
      isLoadingTable.value = false
      isLoadingData.value = false
    }

    const getCandidate = evotingUuid => {
      context.root.$services.ApiServices.get('evoting', evotingUuid).then(
        ({ data }) => {
          election.value.name = data.data.name
          election.value.class_uuid = data.data.class.uuid
          election.value.start_date = data.data.start_date
          election.value.end_date = data.data.end_date

          arrayName.value = []
          data.data.candidate.forEach((item, index) => {
            image.value.push(new File([item.image], item.image, { type: 'image/jpg' }))
            arrayName.value.push({
              index: index + 1,
              uuid: item.uuid,
              name: item.name,
            })
          })
          modalDialog.value = true
        },
        err => console.error(err),
      )
    }

    const showFormEdit = async uuid => {
      dialog.value = 'edit'
      getCandidate(uuid)
      election.value.uuid = uuid
    }

    const update = async () => {
      isLoadingButton.value = true
      const formData = new FormData()
      formData.append('name', election.value.name)
      formData.append('class_uuid', election.value.class_uuid)
      formData.append('start_date', election.value.start_date)
      formData.append('end_date', election.value.end_date)

      arrayName.value.forEach((el, index) => {
        if (imageInput.value[index] !== 'null' && typeof imageInput.value[index] !== 'undefined') {
          formData.append('candidate_uuid[]', el.uuid)
          formData.append('candidate[]', el.name)
          formData.append('image[]', imageInput.value[index])
        } else {
          formData.append('candidate_uuid[]', el.uuid)
          formData.append('candidate[]', el.name)
        }
      })
      await context.root.$services.ApiServices.update('evoting', formData, election.value.uuid).then(
        ({ data }) => {
          resetForm()
          modalDialog.value = false
          showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          isLoadingButton.value = false
          resetForm()
          console.error(err)
        },
      )
      await listElection(filterQuery.value)
      isLoadingButton.value = false
    }
    const handlerButton = () => {
      if (dialog.value === 'add') add()
      else update()
    }

    const deleteCandidate = async (uuid, evotingUuid) => {
      if (uuid === '') {
        showSnackbar({
          text: 'Tidak dapat menghapus karena tidak ada Kandidat',
          color: 'error',
        })
      } else {
        await context.root.$services.ApiServices.destroy('candidate', uuid).then(
          ({ data }) => {
            getCandidate(evotingUuid)
            listElection()
            showSnackbar({
              text: data.result.message,
              color: 'success',
            })
          },
          err => console.error(err),
        )
      }
    }

    const confirmDestroy = uuid => {
      confirmDialog.value = true
      election.value.uuid = uuid
    }

    const destroy = async uuid => {
      isLoadingButton.value = true
      await context.root.$services.ApiServices.destroy('evoting', election.value.uuid).then(
        ({ data }) => {
          listElection()
          showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
      isLoadingButton.value = false
      confirmDialog.value = false
    }

    const paginationHandler = pageNumber => {
      page.value = pageNumber
    }

    const searchHandler = election => {
      filterQuery.value.search = election
      listElection(filterQuery.value)
    }
    const autofillStartDate = () => {
      election.value.start_date = new Date().toISOString().substr(0, 10)
    }

    const autofillEndDate = () => {
      election.value.end_date = new Date().toISOString().substr(0, 10)
    }

    watch(page, listElection)
    watch(election.value, () => {
      const valid = []
      const requiredField = ['name', 'class_uuid', 'start_date', 'end_date']
      Object.entries(election.value).forEach(([key, item]) => {
        if (requiredField.includes(key)) valid.push(!!(item && item.length))
      })
      formValid.value = !valid.includes(false)
    })
    onMounted(() => {
      getSchoolUuid()
      getClass()
      checkClassUuid()
      listElection()
    })

    return {
      getSchoolUuid,
      school,
      schoolUuid,
      userUuid,
      classUuid,
      checkClassUuid,
      candidateUuid,
      getCandidate,
      autofillStartDate,
      autofillEndDate,
      icons,
      arrayName,
      image,
      imageInput,
      update,
      deleteCandidate,
      paginationHandler,
      searchHandler,
      confirmDestroy,
      destroy,
      candidate,
      add,
      showSnackbar,
      addInput,
      deleteInput,
      onMounted,
      watch,
      listElection,
      elections,
      election,
      showFormAdd,
      showFormEdit,
      resetForm,
      headers,
      attrs,
      page,
      search,
      dialog,
      width,
      totalPages,
      totalItems,
      modalDialog,
      confirmDialog,
      isLoadingTable,
      isLoadingData,
      isLoadingButton,
      handlerButton,
      getFile,
      getClass,
      classes,
      startAtMenu,
      endAtMenu,
      filterQuery,
      formValid,
    }
  },

  // data() {
  //   return {
  //     page: 1,
  //     totalItems: 10,
  //     totalPages: 0,
  //     modalDialog: false,
  //     width: '1080',
  //     headers: [
  //       { text: 'No', value: 'no' },
  //       { text: 'Jenis Pemilihan', value: 'election_type' },
  //       { text: 'Kandidat', value: 'candidate_name' },
  //       { text: 'Kelas', value: 'class' },
  //       { text: 'Tanggal Mulai', value: 'start_date' },
  //       { text: 'Tanggal Berhenti', value: 'end_date' },
  //       { text: 'Actions', value: 'actions' },
  //     ],
  //     elections: [
  //       {
  //         no: '#',
  //         election_type: 'Ketua Osis',
  //         candidate_name: 'Chairul Tanjung',
  //         class: 'Kelas 3',
  //         start_date: '24/12/2021',
  //         end_date: '31/12/2021',
  //       },
  //       {
  //         no: '#',
  //         election_type: 'Ketua Osis',
  //         candidate_name: 'Rina Tanjung',
  //         class: 'Kelas 3',
  //         start_date: '24/12/2021',
  //         end_date: '31/12/2021',
  //       },
  //       {
  //         no: '#',
  //         election_type: 'Ketua Osis',
  //         candidate_name: 'Putera Tanjung',
  //         class: 'Kelas 3',
  //         start_date: '24/12/2021',
  //         end_date: '31/12/2021',
  //       },
  //       {
  //         no: '#',
  //         election_type: 'Ketua Osis',
  //         candidate_name: 'Dina Lestari',
  //         class: 'Kelas 3',
  //         start_date: '24/12/2021',
  //         end_date: '31/12/2021',
  //       },
  //     ],
  //   }
  // },
  // methods: {
  //   showFormAdd() {
  //     this.modalDialog = true
  //   },
  // },
}
</script>

<style>
.v-list-item {
  min-height: 30px !important;
}

td > div > .v-list {
  background-color: transparent !important;
}
</style>
